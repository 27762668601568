const Colors = {
    primary: {
        main: '#00A19B',
        a50: '#00A19B50',
        a20: '#00A19B20',
    },
    secondary: {
        main: '#00000099',
        a50: '#00000050',
        a20: '#00000020',
    },
    info: {
        main: '#0288d1',
        a50: '#0288d150',
        a20: '#0288d120',
    },
    error: {
        main: '#d32f2f',
        a50: '#d32f2f50',
        a20: '#d32f2f20',
    },
    warning: {
        main: '#ed6c02',
        a50: '#ed6c0250',
        a20: '#ed6c0220',
    },
    components: {
        divider: '#00000012',
    },
}

export default Colors

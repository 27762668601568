import { FunctionComponent, useContext, useState } from 'react'
import { useCookies } from 'react-cookie'
import BurgerMenuIcon from '../../assets/BurgerMenu.png'
import BurgerMenuWhite from '../../assets/BurgerMenuWhite.png'
import NotificationIcon from '../../assets/icons/ic_notification.svg'
import { SelectedTab } from '../../libary/Models'
import { AccountButtonContainer } from './styles'

import { useAuth0 } from '@auth0/auth0-react'
import { ThemeContext } from '../../App'
import { useFeatures } from '../../libary/hooks/useFeatures'
import useMe from '../../libary/hooks/useMe'
import FloatingNotificationsPanel from '../Notifications/FloatingNotificationsPanel'
import BurgerMenu from './BurgerMenu'
import './styles.css'

const MiniNavButton: FunctionComponent<{
    onClick: Function
    white: boolean
}> = (props: { onClick: Function; white: boolean }) => {
    if (props.white === true) {
        return (
            <div className='burgerMenuButton' onClick={() => props.onClick()}>
                <img alt='menu' src={BurgerMenuWhite} />
            </div>
        )
    } else {
        return (
            <div className='burgerMenuButton' onClick={() => props.onClick()}>
                <img alt='menu' src={BurgerMenuIcon} />
            </div>
        )
    }
}
interface NavBarNewProps {
    // loggedIn: boolean,: boolean
    setTab?: Function
    selectedTab?: SelectedTab
    hideButtons?: boolean
    loggedOut?: boolean
}

// const adiv = (props: JsxProps, {children}) => {
//     return <div onKeyPress={() => props.onClick()} onClick={() => props.onClick()}>{children}</div>
// }
const NavBarNew: FunctionComponent<NavBarNewProps> = (
    props: NavBarNewProps
) => {
    const theme = useContext(ThemeContext)
    const [showNotifications, setShowNotifications] = useState<boolean>(false)
    const [showBurgerMenu, setShowBurgerMenu] = useState<boolean>(false)
    const { isAuthenticated, logout } = useAuth0()
    const { features } = useFeatures()
    const { me } = useMe()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookies, setCookie, removeCookie] = useCookies()

    const logoutPressed = () => {
        let logoutUrl = 'http://localhost:3000'
        if (window.location.hostname.includes('fedworks')) {
            logoutUrl = 'https://fedworks.org'
        } else if (window.location.hostname.includes('gmworkforce')) {
            logoutUrl = 'https://gmworkforcebank.co.uk'
        } else {
            logoutUrl = 'http://localhost:3000'
        }

        const returnTo =
            process.env.NODE_ENV === 'production'
                ? logoutUrl
                : 'http://localhost:3000'
        removeCookie('token')
        sessionStorage.clear()
        logout({ returnTo })
    }

    const navigate = (location: string) => {
        window.history.pushState(null, null, location)
        window.location.replace(location)
    }

    const renderNavButtons = () => {
        if (props.hideButtons !== undefined && props.hideButtons === true) {
            return
        }
        return (
            <div className='menu'>
                {features.manageRota === true && (
                    <div
                        className='nav-bar-new-button'
                        tabIndex={1}
                        style={{
                            borderBottom:
                                props.selectedTab === SelectedTab.Rota
                                    ? `2px solid ${theme.colors.brand}`
                                    : 'none',
                        }}
                        onClick={() => {
                            navigate('/rota')
                        }}
                        onKeyPress={() => {
                            navigate('/rota')
                        }}>
                        Rota
                    </div>
                )}
                {features.viewStaffList === true && (
                    <div
                        className='nav-bar-new-button'
                        tabIndex={1}
                        style={{
                            borderBottom:
                                props.selectedTab === SelectedTab.StaffHubs
                                    ? `2px solid ${theme.colors.brand}`
                                    : 'none',
                        }}
                        onClick={() => navigate('/staffhubs')}>
                        Staff/Hubs
                    </div>
                )}
                {features.approveStaff && (
                    <div
                        className='nav-bar-new-button'
                        tabIndex={1}
                        style={{
                            borderBottom:
                                props.selectedTab === SelectedTab.StaffApproval
                                    ? `2px solid ${theme.colors.brand}`
                                    : 'none',
                        }}
                        onClick={() => {
                            navigate('/staffapproval')
                        }}>
                        Staff Approval
                    </div>
                )}
                {features.editPermissions === true && (
                    <div
                        className='nav-bar-new-button'
                        tabIndex={1}
                        style={{
                            borderBottom:
                                props.selectedTab ===
                                SelectedTab.PermissionsRequests
                                    ? `2px solid ${theme.colors.brand}`
                                    : 'none',
                        }}
                        onClick={() => navigate('/permissions-requests')}>
                        Permissions Requests
                    </div>
                )}
                {features.findSessions === true && (
                    <div
                        className='nav-bar-new-button'
                        tabIndex={1}
                        style={{
                            borderBottom:
                                props.selectedTab === SelectedTab.MyDiary
                                    ? `2px solid ${theme.colors.brand}`
                                    : 'none',
                        }}
                        onClick={() => navigate('/diary')}>
                        My Diary
                    </div>
                )}
                {features.findSessions === true && (
                    <div
                        className='nav-bar-new-button'
                        tabIndex={1}
                        style={{
                            borderBottom:
                                props.selectedTab === SelectedTab.FindASession
                                    ? `2px solid ${theme.colors.brand}`
                                    : 'none',
                        }}
                        onClick={() => navigate('/search')}>
                        Find A Session
                    </div>
                )}
                {features.requestPermissions === true && (
                    <div
                        className='nav-bar-new-button'
                        tabIndex={1}
                        style={{
                            borderBottom:
                                props.selectedTab ===
                                SelectedTab.RequestPermissions
                                    ? `2px solid ${theme.colors.brand}`
                                    : 'none',
                        }}
                        onClick={() =>
                            navigate(
                                me.staff_type === 'CREATOR'
                                    ? '/permissions-create-home'
                                    : '/permissions-apply-home'
                            )
                        }>
                        Request Permissions
                    </div>
                )}
                {features.viewAnalytics === true && (
                    <div
                        className='nav-bar-new-button'
                        tabIndex={1}
                        style={{
                            borderBottom:
                                props.selectedTab === SelectedTab.Analytics
                                    ? `2px solid ${theme.colors.brand}`
                                    : 'none',
                        }}
                        onClick={() => navigate('/analytics')}>
                        Analytics
                    </div>
                )}
                {features.editStaffTypes === true && (
                    <div
                        className='nav-bar-new-button'
                        tabIndex={1}
                        style={{
                            borderBottom:
                                props.selectedTab === SelectedTab.Analytics
                                    ? `2px solid ${theme.colors.brand}`
                                    : 'none',
                        }}
                        onClick={() => navigate('/admin')}>
                        Admin
                    </div>
                )}
                {features.manageRecurrences === true && (
                    <div
                        className='nav-bar-new-button'
                        tabIndex={1}
                        style={{
                            borderBottom:
                                props.selectedTab === SelectedTab.Analytics
                                    ? `2px solid ${theme.colors.brand}`
                                    : 'none',
                        }}
                        onClick={() => navigate('/recurrences')}>
                        Scheduling
                    </div>
                )}
                {features.canDeleteUsers === true && (
                    <div
                        className='nav-bar-new-button'
                        tabIndex={1}
                        style={{
                            borderBottom:
                                props.selectedTab === SelectedTab.StaffNew
                                    ? `2px solid ${theme.colors.brand}`
                                    : 'none',
                        }}
                        onClick={() => navigate('/staff-list-new')}>
                        Staff (New)
                    </div>
                )}
            </div>
        )
    }

    const renderRightNavButtons = () => {
        const type = me.staff_type
        return (
            <AccountButtonContainer className='menu'>
                {(props.hideButtons ?? false) === false && (
                    <img
                        className='nav-bar-new-notification-icon'
                        src={NotificationIcon}
                        onClick={() => setShowNotifications(!showNotifications)}
                    />
                )}
                {type !== 'ADMIN' && (props.hideButtons ?? false) === false && (
                    <div
                        className='nav-bar-new-button'
                        tabIndex={1}
                        style={{
                            borderBottom:
                                props.selectedTab ===
                                SelectedTab.RequestPermissions
                                    ? `2px solid ${theme.colors.brand}`
                                    : 'none',
                        }}
                        onClick={() => {
                            navigate('/account')
                        }}>
                        Account
                    </div>
                )}
                {type === 'ADMIN' && (
                    <div
                        className='nav-bar-new-button'
                        tabIndex={1}
                        style={{
                            borderBottom:
                                props.selectedTab ===
                                SelectedTab.RequestPermissions
                                    ? `2px solid ${theme.colors.brand}`
                                    : 'none',
                        }}
                        onClick={() => {
                            navigate('/admin')
                        }}>
                        Admin Panel
                    </div>
                )}
                <div
                    className='nav-bar-new-button'
                    tabIndex={1}
                    style={{
                        borderBottom:
                            props.selectedTab === SelectedTab.RequestPermissions
                                ? `2px solid ${theme.colors.brand}`
                                : 'none',
                    }}
                    onClick={() => {
                        logoutPressed()
                    }}>
                    Logout
                </div>
            </AccountButtonContainer>
        )
    }

    const render = () => {
        if ((props.loggedOut ?? false) === true) {
            return (
                <div className='navBarContainer'>
                    <img
                        src={theme.images.logo}
                        className='nav-bar-logo'
                        onClick={() => navigate('/')}
                    />
                </div>
            )
        } else {
            return (
                <div className='navBarContainer'>
                    <img
                        src={theme.images.logo}
                        className='nav-bar-logo'
                        onClick={() => navigate('/')}
                    />
                    {isAuthenticated === true && renderNavButtons()}
                    {isAuthenticated === true && renderRightNavButtons()}

                    {showNotifications === true && (
                        <FloatingNotificationsPanel
                            closeNotifications={() => {
                                setShowNotifications(false)
                            }}
                        />
                    )}

                    {isAuthenticated && (
                        <MiniNavButton
                            onClick={() => {
                                setShowBurgerMenu(!showBurgerMenu)
                            }}
                            white={false}
                        />
                    )}
                    {showBurgerMenu && (
                        <BurgerMenu
                            open={showBurgerMenu}
                            admin={me.staff_type === 'ADMIN'}
                        />
                    )}
                </div>
            )
        }
    }

    return render()
}

export default NavBarNew

import { gql } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import {
    FunctionComponent,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import ReactSwitch from 'react-switch'
import { ThemeContext } from '../../App'
import DataTableSearchView from '../../components/DataTable/DataTableSearchView/DataTableSearchView'
import Footer from '../../components/Footer'
import NavBarNew from '../../components/NavBarNew'
import ConfirmDeleteModal from '../../components/modals/ConfirmDeleteModal'
import { ConfirmDeleteType } from '../../components/modals/ConfirmDeleteModal/ConfirmDeleteModal'
import StaffModalNew from '../../components/modals/StaffModalNew'
import AuthContainer from '../../container/AuthContainer'
import { SelectedTab, StaffModel } from '../../libary/Models'
import client from '../../libary/apollo'
import useFeatures from '../../libary/hooks/useFeatures'
import useToken from '../../libary/hooks/useToken'
import StaffColumns from './StaffColumns'
import './styles.css'
const query = gql`
    query {
        staff {
            id
            name_value
            staff_type
            image
        }
    }
`

const deleteUserMutation = gql`
    mutation DeleteUser($staff_id: String!) {
        deleteUser(staff_id: $staff_id) {
            success
        }
    }
`

const StaffNew: FunctionComponent = () => {
    const theme = useContext(ThemeContext)
    const renderToggles = () => {
        return (
            <div className='staff-list-filter-type-toggles'>
                <div className='staff-list-filter-toggle'>
                    <div className='staff-list-filter-label'>GP</div>
                    <ReactSwitch
                        onColor={theme.colors.brand}
                        onChange={() => setIsGP(!isGP)}
                        checked={isGP}
                    />
                </div>
                <div className='staff-list-filter-toggle'>
                    <div className='staff-list-filter-label'>ANP</div>
                    <ReactSwitch
                        onColor={theme.colors.brand}
                        onChange={() => setIsANP(!isANP)}
                        checked={isANP}
                    />
                </div>
                <div className='staff-list-filter-toggle'>
                    <div className='staff-list-filter-label'>MSK</div>
                    <ReactSwitch
                        onColor={theme.colors.brand}
                        onChange={() => setIsMSK(!isMSK)}
                        checked={isMSK}
                    />
                </div>
                <div className='staff-list-filter-toggle'>
                    <div className='staff-list-filter-label'>MHP</div>
                    <ReactSwitch
                        onColor={theme.colors.brand}
                        onChange={() => setIsMHP(!isMHP)}
                        checked={isMHP}
                    />
                </div>
                <div className='staff-list-filter-toggle'>
                    <div className='staff-list-filter-label'>Nurse</div>
                    <ReactSwitch
                        onColor={theme.colors.brand}
                        onChange={() => setIsNurse(!isNurse)}
                        checked={isNurse}
                    />
                </div>
                <div className='staff-list-filter-toggle'>
                    <div className='staff-list-filter-label'>HCA</div>
                    <ReactSwitch
                        onColor={theme.colors.brand}
                        onChange={() => setIsHCA(!isHCA)}
                        checked={isHCA}
                    />
                </div>
                <div className='staff-list-filter-toggle'>
                    <div className='staff-list-filter-label'>PA</div>
                    <ReactSwitch
                        onColor={theme.colors.brand}
                        onChange={() => setIsPA(!isPA)}
                        checked={isPA}
                    />
                </div>
                <div className='staff-list-filter-toggle'>
                    <div className='staff-list-filter-label'>Paramedic</div>
                    <ReactSwitch
                        onColor={theme.colors.brand}
                        onChange={() => setIsParamedic(!isParamedic)}
                        checked={isParamedic}
                    />
                </div>
                <div className='staff-list-filter-toggle'>
                    <div className='staff-list-filter-label'>Receptionist</div>
                    <ReactSwitch
                        onColor={theme.colors.brand}
                        onChange={() => setIsReceptionist(!isReceptionist)}
                        checked={isReceptionist}
                    />
                </div>
            </div>
        )
    }
    const [isGP, setIsGP] = useState<boolean>(true)
    const [isANP, setIsANP] = useState<boolean>(true)
    const [isMSK, setIsMSK] = useState<boolean>(true)
    const [isMHP, setIsMHP] = useState<boolean>(true)
    const [isNurse, setIsNurse] = useState<boolean>(true)
    const [isHCA, setIsHCA] = useState<boolean>(true)
    const [isPA, setIsPA] = useState<boolean>(true)
    const [isParamedic, setIsParamedic] = useState<boolean>(true)
    const [isReceptionist, setIsReceptionist] = useState<boolean>(true)

    const filterByStaffType = (staffModel) => {
        var types = []
        if (isGP) {
            types.push('GP')
        }
        if (isANP) {
            types.push('ANP')
        }
        if (isMSK) {
            types.push('MSK')
        }
        if (isMHP) {
            types.push('MHP')
        }
        if (isNurse) {
            types.push('NURSE')
        }
        if (isHCA) {
            types.push('HCA')
        }
        if (isPA) {
            types.push('PA')
        }
        if (isParamedic) {
            types.push('PARAMEDIC')
        }
        if (isReceptionist) {
            types.push('RECEPTIONIST')
        }
        return types.includes(staffModel.staff_type)
    }

    const [selectedStaff, setSelectedStaff] = useState<StaffModel | null>(null)
    const [staffToDelete, setStaffToDelete] = useState<StaffModel | null>(null)
    const [staff, setStaff] = useState<StaffModel[] | undefined>()
    const { features } = useFeatures()

    useEffect(() => {
        if (features === null) {
            return
        }
        if (features.canDeleteUsers !== true) {
            window.location.replace('/no')
        }
    }, [features])

    const closeModal = () => {
        setSelectedStaff(null)
    }

    const { getAccessTokenSilently } = useAuth0()

    useEffect(() => {
        loadStaff()
    }, [])

    const loadStaff = () => {
        ;(async () => {
            const token = await getAccessTokenSilently()
            client
                .query({
                    query,
                    context: {
                        headers: {
                            authorization: token,
                        },
                    },
                })
                .then((response) => {
                    setStaff(response.data.staff)
                })
        })()
    }
    const token = useToken()

    const deleteStaffMember = () => {
        client
            .mutate({
                mutation: deleteUserMutation,
                variables: {
                    staff_id: staffToDelete.id,
                },
                context: {
                    headers: {
                        Authorization: token,
                    },
                },
            })
            .then((response) => {
                if (response.data.deleteUser.success === true) {
                    const newStaff = staff.filter(
                        (s) => s.id !== staffToDelete.id
                    )
                    console.log(newStaff)
                    setStaff(newStaff)
                    setStaffToDelete(undefined)
                }
            })
    }

    const columns = StaffColumns(setSelectedStaff, setStaffToDelete)
    const filteredStaff = useMemo(() => {
        if (staff === undefined) {
            return []
        }
        const s = staff
            .filter(filterByStaffType)
            .filter((staffModel) => staffModel.name_value != null)
        return s
    }, [
        isGP,
        isANP,
        isMSK,
        isMHP,
        isNurse,
        isHCA,
        isPA,
        isParamedic,
        isReceptionist,
        staff,
    ])

    return (
        <AuthContainer>
            <div className='staffNewBody'>
                <NavBarNew
                    setTab={() => {}}
                    selectedTab={SelectedTab.StaffNew}
                />
                <div className='staffNewContainer'>
                    <DataTableSearchView
                        columns={columns}
                        data={filteredStaff}
                        paginationProps={{
                            pageSizeOptions: [10],
                            paginationMode: 'client',
                            rowCount: staff ? staff.length : 0,
                            loading: staff === undefined,
                        }}
                        toolbarProps={{
                            title: 'Staff',
                            renderToolbar: true,
                            tabs: renderToggles(),
                            // alertButtonMessage: 'Pending Approval',
                            // alertButtonPressed: () => {
                            //     navigate('/')
                            // },
                        }}
                    />
                </div>
                <Footer />
            </div>
            {staffToDelete && (
                <ConfirmDeleteModal
                    deleteType={ConfirmDeleteType.USER}
                    show={staffToDelete !== undefined}
                    closeModal={() => {
                        setStaffToDelete(undefined)
                    }}
                    confirmAction={() => {
                        deleteStaffMember()
                    }}
                />
            )}
            {selectedStaff !== null && (
                <StaffModalNew
                    open={selectedStaff != null}
                    id={selectedStaff.id}
                    closeModal={closeModal}
                />
            )}
        </AuthContainer>
    )
}

export default StaffNew

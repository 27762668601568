import { FunctionComponent } from 'react'
import { NewModal } from '../../NewModal'

import './styles.css'

export enum ConfirmDeleteType {
    SESSION = 'session',
    RECURRENCE = 'schedule',
    SCHEDULE_SESSION = 'schedule_session',
    USER = 'user',
}

interface AddSystemModalProps {
    show: boolean
    closeModal: Function
    deleteType: ConfirmDeleteType
    confirmAction: Function
}

const ConfirmDeleteModal: FunctionComponent<AddSystemModalProps> = (
    props: AddSystemModalProps
) => {
    return (
        <NewModal show={props.show} close={props.closeModal}>
            <div className='confirm-delete-modal-container'>
                <p className='confirm-delete-modal-title'>
                    {props.deleteType === ConfirmDeleteType.SCHEDULE_SESSION &&
                        `Are you sure you want to exclude this date from the recurrence?`}
                    {props.deleteType !== ConfirmDeleteType.SCHEDULE_SESSION &&
                        `Are you sure you want to delete this ${props.deleteType}`}
                </p>
                <div className='confirm-delete-button-container'>
                    <div
                        className={'confirm-delete-button'}
                        onClick={() => {
                            props.confirmAction()
                        }}>
                        Yes
                    </div>
                    <div
                        className={'confirm-delete-button no'}
                        onClick={() => {
                            props.closeModal()
                        }}>
                        No
                    </div>
                </div>
            </div>
        </NewModal>
    )
}

export default ConfirmDeleteModal

import {
    AddCircleOutlineRounded,
    ArchiveOutlined,
    DeleteRounded,
    EditRounded,
    Unarchive,
    VisibilityOutlined,
} from '@mui/icons-material'
import { CircularProgress, IconButton } from '@mui/material'
import { FunctionComponent } from 'react'
import LightTooltip from '../../Tooltips/LightTooltip'
import './styles.css'

export interface ActionsCellAction {
    title: string
    action: () => void
    icon: ActionButtonIcon
    tooltip?: string
}

export enum ActionButtonIcon {
    DELETE = 'DELETE',
    EDIT = 'EDIT',
    CREATE = 'CREATE',
    VIEW = 'VIEW',
    ARCHIVE = 'ARCHIVE',
    UNARCHIVE = 'UNARCHIVE',
    LOADING = 'LOADING',
}

interface ActionsCellProps {
    actions: ActionsCellAction[]
}

const ActionButton = (props: { action: ActionsCellAction }) => {
    const getIcon = () => {
        switch (props.action.icon) {
            case ActionButtonIcon.DELETE:
                return <DeleteRounded />
            case ActionButtonIcon.EDIT:
                return <EditRounded />
            case ActionButtonIcon.CREATE:
                return <AddCircleOutlineRounded />
            case ActionButtonIcon.VIEW:
                return <VisibilityOutlined />
            case ActionButtonIcon.ARCHIVE:
                return <ArchiveOutlined />
            case ActionButtonIcon.UNARCHIVE:
                return <Unarchive />
            case ActionButtonIcon.LOADING:
                return <CircularProgress size={24} color='inherit' />
        }
    }

    const getColor = () => {
        switch (props.action.icon) {
            case ActionButtonIcon.DELETE:
                return 'error'
            case ActionButtonIcon.UNARCHIVE:
                return 'default'
            case ActionButtonIcon.ARCHIVE:
                return 'default'
            case ActionButtonIcon.LOADING:
                return 'default'
            default:
                return 'primary'
        }
    }

    return (
        <LightTooltip
            title={props.action.tooltip}
            sx={{ pointerEvents: 'none' }}>
            <IconButton
                style={{ marginBlock: '4px' }}
                onClick={(e) => {
                    e.stopPropagation()
                    props.action.action()
                }}
                size='small'
                color={getColor()}>
                {getIcon()}
            </IconButton>
        </LightTooltip>
    )
}

const ActionsCell: FunctionComponent<ActionsCellProps> = (
    props: ActionsCellProps
) => {
    return (
        <div className='actions-cell-body'>
            {props.actions.map((action: ActionsCellAction) => {
                return <ActionButton key={action.title} action={action} />
            })}
        </div>
    )
}

export default ActionsCell

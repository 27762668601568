import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material'
import React from 'react'
import Colors from '../../assets/colors'

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: Colors.secondary.main,
        boxShadow: theme.shadows[2],
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
    },
}))

interface LightTooltipProps extends TooltipProps {
    enablePointerEvents?: boolean
}

const LightTooltip: React.FC<LightTooltipProps> = ({
    enablePointerEvents = false,
    ...props
}) => {
    return (
        <StyledTooltip
            {...props}
            sx={{ pointerEvents: enablePointerEvents ? 'auto' : 'none' }}
        />
    )
}

export default LightTooltip

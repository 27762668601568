import { gridClasses } from '@mui/x-data-grid'
import Colors from '../../../assets/colors'

const myDataGridStyles = (flexColumnTitleContent: boolean) => ({
    border: 0,
    [`& .${gridClasses.cell}`]: {
        paddingY: '8px',
    },
    [`& .${gridClasses['row']}:focus-within`]: {
        backgroundColor: Colors.primary.a20,
    },
    [`& .${gridClasses['cell']}:focus-within`]: {
        outline: 'none',
    },
    [`& .${gridClasses['columnHeader']}:hover`]: {
        borderBottom: `solid 2px ${Colors.primary.main}`,
    },
    [`& .${gridClasses['columnHeader--emptyGroup']}:hover`]: {
        borderBottom: 'none',
        outline: 'none',
    },
    [`& .${gridClasses['columnHeader--filledGroup']}:hover`]: {
        borderBottom: 'none',
    },
    [`& .${gridClasses['columnHeader--emptyGroup']} .${gridClasses['columnSeparator']}`]:
        {
            display: 'none',
        },
    [`& .${gridClasses['columnHeader--filledGroup']} .${gridClasses['columnSeparator']}`]:
        {
            display: 'none',
        },
    [`& .${gridClasses['columnHeader--filledGroup']} .${gridClasses['columnHeaderTitleContainer']}`]:
        {
            borderColor: Colors.primary.a50,
            borderRadius: '8px',
            backgroundColor: Colors.primary.a20,
        },
    [`& .${gridClasses['columnHeader--filledGroup']} .${gridClasses['columnHeaderTitleContainerContent']}`]:
        {
            color: Colors.primary.main,
        },
    [`& .${gridClasses['columnHeader']}:focus-within`]: {
        outline: 'none',
    },
    [`& .${gridClasses['columnHeaderTitleContainerContent']}`]:
        flexColumnTitleContent
            ? {
                  flex: 1,
              }
            : {},
})

export default myDataGridStyles

import {
    DataGrid,
    DataGridProps,
    GridColumnVisibilityModel,
} from '@mui/x-data-grid'
import React, { FC, useState } from 'react'

const MyDataGrid: FC<DataGridProps> = (props: DataGridProps) => {
    const dataGridProps = props
    const initialVisibilityModel: GridColumnVisibilityModel = {}
    props.columns.forEach((column) => {
        initialVisibilityModel[column.field] = true
    })

    const [columnVisibilityModel, setColumnVisibilityModel] =
        useState<GridColumnVisibilityModel>(initialVisibilityModel)

    const handleColumnVisibilityModelChange = (
        newModel: GridColumnVisibilityModel,
    ) => {
        const visibleColumnsCount = Object.values(newModel).filter(
            (value) => value,
        ).length

        if (visibleColumnsCount === 0) {
            return
        }

        setColumnVisibilityModel(newModel)
    }

    return (
        <DataGrid
            {...dataGridProps}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
            getRowHeight={() => 'auto'}
            disableRowSelectionOnClick
        />
    )
}

export default MyDataGrid

import { AddCircleOutlineRounded, FilterAlt } from '@mui/icons-material'
import {
    Badge,
    Box,
    Button,
    Divider,
    IconButton,
    Portal,
    Stack,
    Typography,
} from '@mui/material'
import {
    GridColumnGroupingModel,
    GridDensity,
    GridFeatureMode,
    GridPaginationModel,
    GridRowClassNameParams,
    GridRowIdGetter,
    GridSortModel,
    GridToolbarColumnsButton,
    GridToolbarExport,
    GridToolbarQuickFilter,
} from '@mui/x-data-grid'
import { FC, ReactNode, useEffect, useState } from 'react'
import LightTooltip from '../../Tooltips/LightTooltip'
import MyDataGrid from '../MyDataGrid/MyDataGrid'
import myDataGridStyles from '../MyDataGrid/myDataGridStyles'
import { DataTableSearchViewColumns } from './models'

export interface PaginationModel {
    page: number
    pageSize: number
}

interface ToolbarProps {
    title?: string
    tabs?: ReactNode
    addButtonPressed?: () => void
    disabledAddButton?: boolean
    alertButtonMessage?: string
    alertButtonPressed?: () => void
    recentCases?: boolean
    renderToolbar?: boolean
    filterButtonPressed?: () => void
    activeFilterCount?: number
}

interface PaginationProps {
    pageSizeOptions: number[]
    rowCount: number
    paginationMode?: GridFeatureMode
    onPaginationModelChange?: (newPaginationModel: GridPaginationModel) => void
    paginationModel?: GridPaginationModel
    loading: boolean
}

interface DataTableSearchViewProps {
    columns: DataTableSearchViewColumns[]
    columnGroupingModel?: GridColumnGroupingModel
    sortModel?: GridSortModel
    sortable?: boolean
    data?: any[]
    title?: string
    tabs?: ReactNode
    filterButtonPressed?: () => void

    onRowSelected?: (id: string) => void
    selectedRow?: string

    addButtonPressed?: () => void
    disabledAddButton?: boolean
    flexColumnTitleContent?: boolean
    rowSelected?: (id: string) => void
    defaultSelected?: string
    hideFooter?: boolean
    density?: GridDensity
    disableColumnMenu?: boolean
    getRowId?: GridRowIdGetter<any> | undefined
    getRowClassName?:
        | ((params: GridRowClassNameParams<any>) => string)
        | undefined
    maxWidth?: string | number
    border?: boolean
    noPadding?: boolean
    onSearch?: (searchTerm: string) => void
    toolbarProps?: ToolbarProps
    paginationProps?: PaginationProps
}

const DataTableSearchView: FC<DataTableSearchViewProps> = ({
    columns,
    columnGroupingModel,
    sortModel,
    sortable,
    data,
    title,
    tabs,
    filterButtonPressed,
    onRowSelected,
    selectedRow,
    addButtonPressed,
    disabledAddButton,
    flexColumnTitleContent = false,
    rowSelected,
    defaultSelected,
    hideFooter,
    density = 'comfortable',
    disableColumnMenu,
    getRowId,
    getRowClassName,
    maxWidth,
    border,
    noPadding,
    onSearch,
    toolbarProps,
    paginationProps,
}) => {
    const [localSelectedRow, setLocalSelectedRow] = useState<
        string | undefined
    >(selectedRow || undefined)

    useEffect(() => {
        if (onRowSelected !== undefined)
            setLocalSelectedRow(selectedRow || undefined)
    }, [selectedRow])

    function SearchBar() {
        return (
            <Portal container={() => document.getElementById('search-bar')!}>
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                    <GridToolbarQuickFilter sx={{ padding: 0 }} />
                    <GridToolbarExport />
                    {!disableColumnMenu && <GridToolbarColumnsButton />}
                </Stack>
            </Portal>
        )
    }

    const renderToolbarComponent = () => {
        if (toolbarProps?.title === undefined && !toolbarProps?.renderToolbar) {
            return
        }
        return (
            <>
                <Stack direction={'row'} alignItems={'center'} height={'72px'}>
                    {toolbarProps?.title && (
                        <Stack
                            direction={'row'}
                            spacing={2}
                            alignItems={'center'}>
                            <Typography variant='h4'>
                                {toolbarProps.title}
                            </Typography>
                            {toolbarProps.tabs && toolbarProps.tabs}
                            {toolbarProps.addButtonPressed && (
                                <LightTooltip
                                    title={`Add ${
                                        toolbarProps.title.endsWith('s')
                                            ? toolbarProps.title.slice(0, -1)
                                            : toolbarProps.title
                                    }`}
                                    disableHoverListener={
                                        toolbarProps.disabledAddButton
                                    }
                                    disableFocusListener={
                                        toolbarProps.disabledAddButton
                                    }>
                                    <span>
                                        <IconButton
                                            disabled={
                                                toolbarProps.disabledAddButton
                                            }
                                            color='primary'
                                            onClick={() =>
                                                toolbarProps.addButtonPressed &&
                                                toolbarProps.addButtonPressed()
                                            }>
                                            <AddCircleOutlineRounded />
                                        </IconButton>
                                    </span>
                                </LightTooltip>
                            )}
                            {toolbarProps.alertButtonMessage &&
                                toolbarProps.alertButtonPressed && (
                                    <Button
                                        variant='text'
                                        color='error'
                                        onClick={() =>
                                            toolbarProps.alertButtonPressed &&
                                            toolbarProps.alertButtonPressed()
                                        }>
                                        ({toolbarProps.alertButtonMessage})
                                    </Button>
                                )}
                        </Stack>
                    )}
                    <Box flexGrow={1} />
                    {(toolbarProps.renderToolbar ||
                        toolbarProps.filterButtonPressed) && (
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            spacing={2}>
                            {toolbarProps.renderToolbar && (
                                <Box id='search-bar' role='toolbar' />
                            )}
                            {toolbarProps.filterButtonPressed && (
                                <Badge
                                    badgeContent={
                                        toolbarProps.activeFilterCount
                                    }
                                    color='info'>
                                    <Button
                                        variant='contained'
                                        size='small'
                                        startIcon={<FilterAlt />}
                                        onClick={() =>
                                            toolbarProps.filterButtonPressed &&
                                            toolbarProps.filterButtonPressed()
                                        }>
                                        Filters
                                    </Button>
                                </Badge>
                            )}
                        </Stack>
                    )}
                </Stack>
                <Divider flexItem />
            </>
        )
    }

    return (
        <Stack height={'100%'} width={'100%'} maxWidth={maxWidth} spacing={2}>
            {renderToolbarComponent()}
            <Stack
                height={'100%'}
                flex={1}
                spacing={4}
                direction={'row'}
                paddingTop={!noPadding ? 2 : 0}
                borderRadius={2}
                border={border ? `solid 1px gray` : 'none'}>
                <MyDataGrid
                    sx={myDataGridStyles(flexColumnTitleContent)}
                    sortModel={sortModel}
                    density={density}
                    hideFooter={hideFooter}
                    columns={columns.map((column) => ({
                        ...column,
                        sortable: sortable,
                    }))}
                    loading={data === null}
                    rows={data || []}
                    slots={
                        toolbarProps?.renderToolbar === true
                            ? { toolbar: SearchBar }
                            : undefined
                    }
                    slotProps={{
                        baseCheckbox: {
                            disableRipple: true,
                        },
                        columnsPanel: {
                            disableHideAllButton: true,
                            disableShowAllButton: true,
                        },
                        baseButton: { variant: 'contained' },
                    }}
                    disableColumnMenu
                    disableColumnFilter
                    onRowClick={(params) => {
                        if (onRowSelected) {
                            setLocalSelectedRow(params.row.id)
                            onRowSelected(params.row.id)
                        }
                    }}
                    rowSelectionModel={
                        onRowSelected
                            ? localSelectedRow
                                ? [localSelectedRow]
                                : []
                            : undefined
                    }
                    getRowId={getRowId}
                    getRowClassName={getRowClassName}
                    {...paginationProps}
                    columnGroupingModel={columnGroupingModel}
                    experimentalFeatures={{ columnGrouping: true }}
                />
            </Stack>
        </Stack>
    )
}

export default DataTableSearchView

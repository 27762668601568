import { GridColDef } from '@mui/x-data-grid'
import { Dispatch, SetStateAction } from 'react'
import ActionsCell from '../../components/DataTable/ActionsCell'
import { ActionButtonIcon } from '../../components/DataTable/ActionsCell/ActionsCell'
import { StaffModel } from '../../libary/Models'

const StaffColumns = (
    setSelectedStaff: Dispatch<SetStateAction<StaffModel | undefined>>,
    setStaffToDelete: Dispatch<SetStateAction<StaffModel | undefined>>
): GridColDef[] => [
    {
        field: 'name_value',
        headerName: 'Staff Name',
    },
    {
        field: 'staff_type',
        headerName: 'Staff Type',
        flex: 1,
    },
    {
        field: 'id',
        headerName: 'Actions',
        renderCell: (params) => {
            return (
                <ActionsCell
                    actions={[
                        {
                            action: () => {
                                setSelectedStaff(params.row)
                            },
                            title: 'View',
                            tooltip: 'View Staff Member',
                            icon: ActionButtonIcon.VIEW,
                        },
                        {
                            action: () => {
                                setStaffToDelete(params.row)
                            },
                            title: 'Delete',
                            tooltip: 'Delete Staff Member',
                            icon: ActionButtonIcon.DELETE,
                        },
                    ]}
                />
            )
        },
    },
]

export default StaffColumns
